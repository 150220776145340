import React from "react";
import type { SVGProps } from "react";

export function PyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27 10.6667H31.6666M20 17.6667V6.00004C20 4.76236 20.4916 3.57538 21.3668 2.70021C22.242 1.82504 23.429 1.33337 24.6666 1.33337H43.3333C44.571 1.33337 45.758 1.82504 46.6331 2.70021C47.5083 3.57538 48 4.76236 48 6.00004V29.3334C48 30.5711 47.5083 31.758 46.6331 32.6332C45.758 33.5084 44.571 34 43.3333 34H24.6666C23.429 34 22.242 34.4917 21.3668 35.3669C20.4916 36.242 20 37.429 20 38.6667V62C20 63.2377 20.4916 64.4247 21.3668 65.2999C22.242 66.175 23.429 66.6667 24.6666 66.6667H43.3333C44.571 66.6667 45.758 66.175 46.6331 65.2999C47.5083 64.4247 48 63.2377 48 62V50.3334M36.3333 20H5.99998C4.7623 20 3.57532 20.4917 2.70015 21.3669C1.82498 22.242 1.33331 23.429 1.33331 24.6667V48C1.33331 49.2377 1.82498 50.4247 2.70015 51.2999C3.57532 52.175 4.7623 52.6667 5.99998 52.6667H20M31.6666 48H62C63.2377 48 64.4246 47.5084 65.2998 46.6332C66.175 45.758 66.6666 44.5711 66.6666 43.3334V20C66.6666 18.7624 66.175 17.5754 65.2998 16.7002C64.4246 15.825 63.2377 15.3334 62 15.3334H48M36.3333 57.3334H41"
        stroke="#F5A524"
        stroke-width="2"
      />
    </svg>
  );
}
