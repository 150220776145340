import React from "react";
import type { SVGProps } from "react";

export function TypeScript(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M57.3334 36.3334V35.554C57.3334 32.1194 54.5474 29.3334 51.1127 29.3334H45.6667C43.8102 29.3334 42.0297 30.0709 40.717 31.3836C39.4042 32.6964 38.6667 34.4769 38.6667 36.3334C38.6667 38.1899 39.4042 39.9704 40.717 41.2831C42.0297 42.5959 43.8102 43.3334 45.6667 43.3334H50.3334C52.1899 43.3334 53.9704 44.0709 55.2831 45.3836C56.5959 46.6964 57.3334 48.4769 57.3334 50.3334C57.3334 52.1899 56.5959 53.9704 55.2831 55.2831C53.9704 56.5959 52.1899 57.3334 50.3334 57.3334H45.6667C43.8102 57.3334 42.0297 56.5959 40.717 55.2831C39.4042 53.9704 38.6667 52.1899 38.6667 50.3334M36.3334 29.3334H13M24.6667 29.3334V59.6667M1.33337 1.33337H66.6667V66.6667H1.33337V1.33337Z" stroke="#006FEE" stroke-width="2" />
    </svg>
  );
}
